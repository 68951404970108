module controllers {
    export module manifest {
        interface IManifestScope extends ng.IScope {
            IsLoading: boolean;
            ShowGrid: boolean;
        }

        export class manifestCreateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', '$timeout', 'bsLoadingOverlayService', 'manifestService',
                "entityService", "manifestTypeService", "FromExisting", "routeCodeService", "counterService"];

            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            ownerEntityId: number;

            manifestTypeList: interfaces.applicationcore.IDropdownModel[];
            manifestTypeId: number;

            manifest: interfaces.applicationcore.IDropdownModel;

            route: interfaces.applicationcore.IDropdownModel;
            routeList: interfaces.applicationcore.IDropdownModel[];

            counterList: interfaces.applicationcore.IDropdownModel[];
            counterId: number;

            customNumberAllowed: boolean = false;
            customNumberCheck: boolean = false;
            customNumber: string;

            constructor(private $scope: IManifestScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private manifestService: interfaces.manifest.IManifestService,
                private entityService: interfaces.applicationcore.IEntityService,
                private manifestTypeService: interfaces.manifest.IManifestTypeService,
                private FromExisting: boolean,
                private routeCodeService: interfaces.master.IRouteCodeService,
                private counterService: interfaces.master.ICounterService) {
                
                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCurrentEntity());
                loadPromises.push(this.loadEntities());
                loadPromises.push(this.loadManifestTypes());
                loadPromises.push(this.loadManifestCounters());

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(loadPromises);
                });
            }

            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            ownerEntityChanged() {
                var loadPromises: ng.IPromise<any>[] = [];

                this.manifestTypeList = [];
                this.manifestTypeId = 0;

                this.routeList = [];
                this.route = undefined;

                this.counterList = [];
                this.counterId = 0;
                
                loadPromises.push(this.loadManifestTypes());
                loadPromises.push(this.loadManifestCounters());
                loadPromises.push(this.loadEntityDefaults());
                
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.$q.all(loadPromises);
                });
            }

            loadCurrentEntity() {
                if (this.$rootScope.Entity.Id) {
                    this.ownerEntityId = this.$rootScope.Entity.Id;
                }
                else {
                    return this.entityService.getCurrentEntity().query((resultEntity: interfaces.applicationcore.IEntity) => {
                        this.ownerEntityId = resultEntity.Id;
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                }
            }

            loadEntityDefaults() {
                return this.manifestService.getDefaults(this.ownerEntityId).get().$promise.then((success) => {
                    this.manifestTypeId = success.ManifestTypeId ? success.ManifestTypeId : this.manifestTypeId;
                    //this.customNumberAllowed = success.AllowCustomCounter;
                    //this.counterId = success.CounterId ? success.CounterId : this.counterId;
                    //this.route = success.Route ? success.Route : this.route;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                });
            }

            loadManifestTypes() {
                return this.manifestTypeService.getDropdown(this.ownerEntityId).query().$promise.then((successList) => {
                    this.manifestTypeList = successList;

                    this.manifestTypeService.getDefaultManifestType(this.ownerEntityId).get().$promise.then((data : interfaces.manifest.IManifestDefaults) => {
                        
                        if (data)
                        {
                            this.manifestTypeId =  data.ManifestTypeId;
                            this.customNumberAllowed = data.AllowCustomCounter;
                            this.counterId = data.CounterId ? data.CounterId : this.counterId;
                            this.route = data.Route ? data.Route : this.route;
                        }
                        else
                        {
                            this.manifestTypeId = _.head(successList).Id;
                            this.manifestTypeChanged()
                        }
                        
                    });


                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                });
            }

            manifestTypeChanged() {
                return this.manifestTypeService.getManifestTypeDefaults(this.manifestTypeId).get().$promise.then((data) => {
                    this.customNumberAllowed = data.AllowCustomCounter;
                    this.counterId = data.CounterId ? data.CounterId : this.counterId;
                    this.route = data.Route ? data.Route : this.route;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                });
            }

            loadManifests(searchText: string) {
                return this.manifestService.getDropdown(this.ownerEntityId, searchText).query((resultList) => {
                    
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            selectedManifestChanged(model: interfaces.applicationcore.IDropdownModel) {
                if(model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.manifestService.getDefaults(undefined, undefined, model.Id).get().$promise.then((success) => {
                            this.manifestTypeId = success.ManifestTypeId ? success.ManifestTypeId : this.manifestTypeId;
                            this.route = success.Route ? success.Route : this.route;
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                    });
                }
            }

            loadManifestCounters() {
                return this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: Enum.EnumCounterTypes.Manifest
                }, (resultList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    this.counterList = resultList;
                    var defaultCounter = _.find(resultList, (o) => {
                        return o.Selected;
                    });

                    if(defaultCounter) {
                        this.counterId = defaultCounter.Id;
                    } else {
                        this.counterId = _.head(resultList).Id;
                    }

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            customNumberCheckChanged() {
                this.customNumber = "";

                if(this.customNumberCheck) {
                    this.counterId = undefined;
                }
            }

            loadRouteCodes() {
                return this.routeCodeService.getDropdownListAll(this.ownerEntityId).query((data) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            selectedRouteChanged(model: interfaces.applicationcore.IDropdownModel) {

                if(model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.manifestService.getDefaults(undefined, model.Id).get().$promise.then((success) => {
                            this.counterId = success.CounterId ? success.CounterId : this.counterId;
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                    });
                }
            }

            close() {
                this.$state.go("^");
            }
            
            create() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.createManifest().then((result) => {
                        this.generalService.displayMessageHandler(result);

                        if(!result.HasErrorMessage) {
                            this.$state.go("auth.Manifests.Update", { manId: Number(result.ID) });
                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }

            createManifest() {
                return this.manifestService.create(this.ownerEntityId, 
                    this.manifestTypeId, 
                    this.counterId,
                    this.customNumber,
                    this.route, 
                    this.manifest).save().$promise;
            }

        };

        angular.module("app").controller("manifestCreateCtrl", controllers.manifest.manifestCreateCtrl);
    }
}